<template lang="pug">
.cursor(ref="cursor")
  .inner
.cursor-text(ref="cursorText")
  .inner 点击探索
.container.black
  .wrapper.relative
    img.bg.absolute.full(src="@/assets/sand/mars2021-1x.jpg" ref="bg")
    canvas.stage.absolute.full(ref="canvas")
    img.fg.absolute(:class="{ full: imageLoaded, invisible: running }" src="@/assets/sand/findx3-1x.jpg" ref="fg")
    img.hide.delay(src="@/assets/sand/delay.png" @load="onTextureLoaded")
</template>

<script>
import { onMounted, onUnmounted, ref, Ref } from 'vue';
import createREGL from 'regl';

import { createSandTextCommand } from '@/commands/sand-text2';
import { useRoute } from 'vue-router';

export default {
  setup() {
    /**
     * @type {Ref<HTMLCanvasElement>}
     */
    const canvas = ref(null);
    const fg = ref(null);
    const bg = ref(null);
    /**
     * @type {Ref<HTMLDivElement>}
     */
    const cursor = ref(null);
    /**
     * @type {Ref<HTMLDivElement>}
     */
    const cursorText = ref(null);
    /**
     * @type {Ref<HTMLDivElement>}
     */
    const cursorContainer = ref(null);
    const running = ref(false);
    const imageLoaded = ref(false);

    const route = useRoute();
    const duration = +route.query.duration || 6000;
    const v = +route.query.v || 2.5;
    console.log(duration, v);

    const createCommand = createSandTextCommand;

    onMounted(async () => {
      const regl = createREGL(canvas.value);
      let texture = regl.texture(Array.from(Array(4)).map(() => [0, 0, 0]));
      document.querySelector('.delay').addEventListener('load', (evt) => {
        texture = regl.texture(evt.target);
      });

      const onClick = async (e) => {
        const { clientX, clientY } = e;
        running.value = true;
        const rect = canvas.value.getBoundingClientRect();
        const x = clientX - rect.x;
        const y = clientY - rect.y;

        window.requestAnimationFrame(() => {
          cursor.value.classList.add('scale');
          cursorText.value.classList.add('scale');
          setTimeout(() => document.querySelector('.stage').classList.add('done'), 190);
        });
        run({ center: [x / rect.width, y / rect.height], duration, v, texture })
          .then(() =>
            window.setTimeout(() => {
              running.value = false;
              window.requestAnimationFrame(() => {
                document.querySelector('.stage').classList.remove('done');
                cursor.value.classList.remove('scale');
                cursorText.value.classList.remove('scale');
              });
            }),
          )
          .catch(() => undefined);
      };
      window.addEventListener('click', onClick);

      const onMouseMove = (e) => {
        const transform = `translate(calc(${e.clientX}px - 50%), calc(${e.clientY}px - 50%))`;
        cursor.value.style.transform = transform;
        cursorText.value.style.transform = transform;
      };
      window.addEventListener('mousemove', onMouseMove);

      onUnmounted(() => {
        window.removeEventListener('click', onClick);
        window.removeEventListener('mousemove', onMouseMove);
        regl.destroy();
      });

      const { run, resolution } = await createCommand(regl, fg.value);
      canvas.value.width = resolution[0];
      canvas.value.height = resolution[1];
      imageLoaded.value = true;

      // window.requestAnimationFrame(() => {
      //   init();
      // });
    });

    return { canvas, fg, bg, cursorContainer, cursor, cursorText, running, imageLoaded };
  },
};
</script>

<style lang="stylus" scoped>
.stage
  cursor none
  &.done
    cursor auto

.cursor, .cursor-text
  position fixed
  z-index 10
  top 0
  left 0
  display flex
  align-items center
  justify-content center
  pointer-events none
  transform translateX(-100vw)

  &.scale .inner
    transform scale(0)
    transition transform .3s

.cursor
  mix-blend-mode difference

  .inner
    width (450 / 1920 * 100vw)
    height @width
    border-radius 50%
    background  #fff

.cursor-text .inner
  // mix-blend-mode difference
  font-size 24px
  letter-spacing 6px
  color #fff
  font-weight 750

.container
  display flex
  justify-content center
  align-items center
  width 100%
  height 100vh
  user-select none

.wrapper
  // width 670px
  // height 445px
  width 1440px
  height 810px
  flex 0 0 auto

.fg
  // mix-blend-mode difference
  pointer-events none
  transition opacity .2s
  &.invisible
    opacity 0

.hide
  display none

.relative
  position relative

.absolute
  position absolute
  top 0
  left 0

.full
  width 100%
  height 100%

.black
  background #000
</style>
