import frag from './sand.frag';
import vert from './sand.vert';

/**
 * @param {HTMLImageElement} image
 */
function waitImageLoaded(image) {
  return new Promise((resolve, reject) => {
    image.addEventListener('load', resolve);
    image.addEventListener('error', reject);
  });
}
/**
 * @param {HTMLImageElement} image
 */
async function getImageData(image, sample = 2) {
  await waitImageLoaded(image);
  const width = image.width;
  const height = image.height;

  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  // canvas.style.position = 'fixed';
  // canvas.style.zIndex = 10;
  // canvas.style.top = '0';
  // document.body.appendChild(canvas);

  const ctx = canvas.getContext('2d');
  ctx.drawImage(image, 0, 0);
  const imageData = new Float32Array(ctx.getImageData(0, 0, canvas.width, canvas.height).data).map(
    (v) => v / 255,
  );

  // console.log(width, height, length, imageData);

  const zNum = 1;
  const colors = [];
  const points = [];
  for (let i = 0; i < height; i += sample) {
    for (let j = 0; j < width; j += sample) {
      const offset = width * i + j;
      const color = [imageData[offset * 4], imageData[offset * 4 + 1], imageData[offset * 4 + 2]];
      const x = (j / width - 0.5) * 2;
      const y = -(i / height - 0.5) * 2;
      for (let z = 0; z < zNum; z += 1) {
        colors.push(color);
        points.push([x, y, z]);
      }
    }
  }

  // console.log(width, height, length, points);
  return { color: colors, point: points, size: sample, resolution: [width, height] };
}

/**
 * @param {import('regl').Regl} regl
 * @param {HTMLImageElement} image 其宽高应该是 auto 的
 */
export async function createSandTextCommand(regl, image) {
  const { color, point, resolution, size } = await getImageData(image);
  // await waitImageLoaded(delayTexture);

  const cmd = regl({
    frag,
    vert,
    attributes: {
      aColor: regl.buffer(color),
      aPos: regl.buffer(point),
    },
    uniforms: {
      uTime: regl.prop('time'),
      uSize: size,
      uResolution: resolution,
      uCenter: regl.prop('center'),
      uRatio: regl.prop('ratio'),
      uV: regl.prop('v'),
      uDelayTexture: regl.prop('texture'),
    },
    depth: { enable: true, mask: true },
    count: point.length,
    blend: {
      enable: false,
      func: {
        src: 'src alpha',
        dst: 'one minus src alpha',
      },
    },
    primitive: 'points',
  });

  let promise;
  const start = async ({
    duration = 1000,
    center: _center = [0, 0],
    v = 2,
    texture = regl.texture({ data: Array.from(Array(4)).map(() => [0, 0, 0]) }),
  }) => {
    if (promise) {
      promise.reject(new Error('interrupted'));
    }
    console.log({ center: _center.toString() });

    const center = [(_center[0] - 0.5) * 2, -(_center[1] - 0.5) * 2];
    const ratio = [
      [-1, 1],
      [1, 1],
      [1, -1],
      [-1, -1],
    ].reduce(
      (max, [x, y]) =>
        Math.max(max, Math.sqrt((x - center[0]) ** 2 + (y - center[1]) ** 2) / Math.SQRT2),
      1,
    );

    /**
     * @type {import('regl').Cancellable}
     */
    let cancelable;
    const p = new Promise((resolve, reject) => {
      promise = { resolve, reject };

      let startedAt;
      cancelable = regl.frame(({ time }) => {
        if (!startedAt) {
          startedAt = time;
        }
        const t = Math.min(((time - startedAt) / ((duration * ratio) / 1000)) * 2, 2);
        cmd({
          v,
          texture,
          ratio,
          time: t,
          center,
        });
        if (t >= 1.1 && cancelable) {
          console.log({ t });
          cancelable.cancel();
          resolve();
        }
      });
    }).catch((e) => {
      if (cancelable) {
        cancelable.cancel();
      }
      throw e;
    });
    return p;
  };

  return {
    resolution,
    run: start,
    init: () => cmd({ time: 0.5, center: [0, 0], v: 2.5, ratio: 1 }),
  };
}
